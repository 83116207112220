<template>
  <b-modal
    v-model="modalShow"
    centered
    :title="selectedMessage.title"
    ok-only
    :ok-title="$t('ok')"
    no-close-on-backdrop
  >
    <div v-if="selectedMessage">
      <h6>
        {{ selectedMessage.subtitle }}
      </h6>
      <p style="word-wrap: break-word;">
        {{ selectedMessage.message }}
      </p>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },

  computed: {
    modalShow: {
      get() {
        return this.$store.state.Messaging.modalShow
      },
      set(newValue) {
        this.$store.commit('Messaging/TOGGLE_MODAL_SHOW', newValue)
      },
    },
    selectedMessage() {
      return this.$store.state.Messaging.selectedMessage
    },
  },
}
</script>
